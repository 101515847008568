export const getDynamicProperties = dynamicProperties => {
  const propsObj = {};
  if (dynamicProperties && dynamicProperties.length > 0) {
    dynamicProperties.forEach(({id, value}) => {
      propsObj[id] = value;
    });
  }

  return propsObj;
};

export const getDynamicPropertyByKey = (dynamicProperties, propKey) => {
  const propObj = dynamicProperties?.find(item => {
    return item.id === propKey;
  });

  return propObj?.value;
};

//for example getDynamicPropertyByKey(profile.dynamicProperties, USER_DYNAMIC_PROPERTIES.memberExpiryDate);
