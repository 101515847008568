export const MAX_MOBILE_WIDTH = 768;
export const COLOR_INACTIVE = '#d5d5d5';
export const COLOR_NEUTRAL = '#666666';
export const COLOR_BTN_NEUTRAL = '#4F4C45';
export const COLOR_LINK = '#80cb9c';
export const COLOR_DEFAULT = '#212529';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_WARNING = '#cc3300';
export const COLOR_SUCCESS = '#339900';

export const TYPE_VISA = 'Visa';
export const TYPE_MASTERCARD = 'Mastercard';

export const HASH = /#/;
export const ABSOLUTE_URL = /^(http[s]?:\/\/|\/)/i;
export const VALID_EMAIL = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const VALID_NAME = /^[a-zA-Z]+$/;
export const EXPIRYDATE_PATTERN = /^[0-9/]+$/g;
export const VALID_NUMBER = /^[0-9]+$/;

export const ORDER_STATE_PENDING_PAYMENT = 'PENDING_APPROVAL';
export const ORDER_STATE_PENDING_APPROVAL_TEMPLATE = 'PENDING_APPROVAL_TEMPLATE';
export const NO_PENDING_ACTION = 'NO_PENDING_ACTION';
export const FULFILLED_STATUS = 'Fulfilled';
export const ASCENDING = 'ascending';
export const DESCENDING = 'descending';

export const CHECKOUT_PAGES = [
  'checkout',
  'checkout-review-order',
  'checkout-payment',
  'checkout-quote-payment-and-review',
  'checkout-shipping',
  'checkout-payer-authentication',
  'checkout-order-confirmation',
  'checkout-login'
];

export const REPORTING_CATEGORIES = {
  events: 'Events',
  exams: 'Exams',
  ebooks: 'eBooks',
  elearning: 'eLearning'
};

export const EVENT_KEY = {
  TAB: 'Tab',
  ENTER: 'Enter'
};

export const INPUT_TYPE = {
  password: 'password',
  text: 'text'
};

export const MS_IN_SEC = 1000;

export const HTTP_METHOD = {
  GET: 'get',
  POST: 'post'
};

export const RESPONSE_ERROR_CODES = {
  INVALID_SELECTED_SM: '28088',
  INVALID_SHIPPING_ADDRESS: '40001'
};

export const OCAR_ID = 'ocar:sameday';

export const MINI_CART_DURATION = 500;
export const MAX_CART_QUANTITY = 500;

export const DATE_OPTIONS = {day: '2-digit', month: 'short', year: 'numeric'};

export const INIT_OFFSET = 0;
export const INIT_PAGINATION = 1;
export const pageChangeOffset = (pageNumber, paginationLimitPerPage) =>
  pageNumber * paginationLimitPerPage - paginationLimitPerPage;

export const LABEL_TEXT = {
  labelCIPSConnect: 'labelCIPSConnect',
  textMyAssessments: 'textMyAssessments',
  labelMyLearning: 'labelMyLearning'
};

export const NO_PRODUCT_STATUS = 'none';

export const ANONYMOUS_USER = 'anonymous';
export * from './language-settings';
export * from './page-links';
export * from './actions';
export * from './icons';
export * from './product-type';
export * from './payments';
export * from './idcs';
export * from './students';
export * from './product-props';
export * from './user-props';
export * from './item-props';
export * from './commerce-item-props';
export * from './order-props';
export * from './organization-props';
export * from './students-and-cohorts-props';
export * from './qualifications-modules';
export * from './month-names';
export * from './grade-types';
export * from './region';
export * from './shipping';
