export const MEMBER_STATUS = {
  CURRENT: 'Current',
  LAPSED: 'Lapsed',
  CANCELLED: 'Cancelled',
  NO_STATUS: 'No Status'
};

export const MEMBER_GRADE = {
  AFFILIATE: 'affiliate'
};

export const MEMBER_SUB_STATUS = {
  gracePeriod: 'Grace Period'
};

export const EXPORTED_STUDENT_DATA_HEADERS = [
  'Email',
  'First name',
  'Surname',
  'Date of birth',
  'Country',
  'Address line 1',
  'Address line 2',
  'City',
  'Postcode',
  'Membership number',
  'Membership status',
  'Membership grade',
  'Membership expiry date'
];
