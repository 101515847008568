export const CURRENCY_ID_GBP = 'gbp';
export const PRICE_LIST_ID_GBP_MEMBERS = 'uKMembers';
export const PRICE_GROUP_MEMBERS_POSTFIX = 'Members';

const getMemberPriceGroupId = (currentPriceListGroupId = '') => {
  if (currentPriceListGroupId === CURRENCY_ID_GBP) {
    return PRICE_LIST_ID_GBP_MEMBERS;
  }

  return `${currentPriceListGroupId}${PRICE_GROUP_MEMBERS_POSTFIX}`;
};

export const getB2CUserPriceGroupId = (
  currentPriceListGroupId,
  isLoggedIn,
  isMember,
  priceListGroupList,
  defaultPriceListGroup
) => {
  if (!isLoggedIn || !isMember) {
    return currentPriceListGroupId;
  }

  let priceListGroupId = null;

  if (isMember) {
    priceListGroupId = getMemberPriceGroupId(currentPriceListGroupId);
  }

  const priceGroupExistsAndActive = priceListGroupList?.find(priceGroup => {
    return priceGroup.active && priceGroup.id === priceListGroupId;
  });

  if (priceGroupExistsAndActive) {
    return priceListGroupId;
  }

  return defaultPriceListGroup.id;
};

export const getPriceGroupIdsBySelectedPriceGroupId = (currentPriceListGroupId = []) => {
  let memberPriceListGroupId, nonMemberPriceListGroupId;

  if (currentPriceListGroupId.indexOf(PRICE_GROUP_MEMBERS_POSTFIX) > -1) {
    memberPriceListGroupId = currentPriceListGroupId;

    if (currentPriceListGroupId === PRICE_LIST_ID_GBP_MEMBERS) {
      nonMemberPriceListGroupId = CURRENCY_ID_GBP;
    } else {
      nonMemberPriceListGroupId = currentPriceListGroupId.replace(PRICE_GROUP_MEMBERS_POSTFIX, '');
    }
  } else {
    nonMemberPriceListGroupId = currentPriceListGroupId;
    memberPriceListGroupId = getMemberPriceGroupId(currentPriceListGroupId);
  }

  return {
    member: memberPriceListGroupId,
    nonMember: nonMemberPriceListGroupId
  };
};
